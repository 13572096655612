<template>
  <div class="goodsAClassify MERCH">
    <div class="merch-card">
      <!-- 搜索列 -->
      <div class="merch-search">
        <div class="search-box">
          <!-- 类型选择 -->
          <div class="sch-1 sch-2">
            <!-- <div class="sch-title">分类状态:</div> -->
            <el-select
              v-model="searchData.status"
              clearable
              
              placeholder="请选择分类状态"
            >
              <el-option
                v-for="item in userType"
                :key="item.type"
                :label="item.name"
                :value="item.type"
              >
              </el-option>
            </el-select>
          </div>
          <!-- 搜索 -->
          <div class="sch-1 sch-2">
            <el-button type="primary" size="mini" @click="toSearch">
              查 询
            </el-button>
            <el-button
              type="primary"
              size="mini"
              @click="toAdd"
              v-if="allJurisdiction.goodsAClassify141"
            >
              新建
            </el-button>
          </div>
        </div>
      </div>
      <!-- 列表 -->
      <div class="merch-table scorr-roll">
        <div>
          <el-table
            :data="classList"
            style="width: 100%; margin-bottom: 20px"
            row-key="id"
            border
            :default-expand-all="false"
            :tree-props="{ children: 'son' }"
          >
            <el-table-column align="left" width="50"></el-table-column>
            <el-table-column prop="id" label="编号" align="center">
            </el-table-column>
            <el-table-column prop="address" label="分类名称" align="center">
              <template slot-scope="{ row }">
                <div>{{ row.category_name }}</div>
              </template>
            </el-table-column>
            <el-table-column prop="address" label="图标" align="center">
              <template slot-scope="{ row }">
                <div>
                  <el-image
                    style="width: 40px; height: 40px"
                    :src="row.icon"
                    :preview-src-list="[row.icon]"
                  >
                  </el-image>
                </div>
              </template>
            </el-table-column>
            <el-table-column prop="address" label="状态" align="center">
              <template slot-scope="{ row }">
                <div>{{ row.status_ == 1 ? "启用" : "停用" }}</div>
              </template>
            </el-table-column>
            <el-table-column
              prop="address"
              label="排序（越大越靠前）"
              align="center"
            >
              <template slot-scope="{ row }">
                <div>{{ row.sort }}</div>
              </template>
            </el-table-column>
            <el-table-column
              prop="address"
              label="操作"
              align="center"
              width="200"
            >
              <template slot-scope="{ row }">
                <div class="userBtn">
                  <!-- <div @click="checkType(row)">
                    {{ row.status_ == 1 ? "停用" : "启用" }}
                  </div> -->
                  <!-- checkType -->
                  <div
                    slot="reference"
                    @click="openDilog(row, 0)"
                    v-if="row.status_ == 1 && allJurisdiction.goodsAClassify143"
                  >
                    禁用
                  </div>
                  <div
                    slot="reference"
                    @click="openDilog(row, 1)"
                    v-if="row.status_ != 1 && allJurisdiction.goodsAClassify143"
                  >
                    启用
                  </div>
                  <div
                    slot="reference"
                    @click="openDilog(row, 2)"
                    v-if="allJurisdiction.goodsAClassify144"
                  >
                    删除
                  </div>
                  <!-- <el-popconfirm
                    v-if="row.status_ == 1 && allJurisdiction.goodsAClassify143"
                  >
                    
                  </el-popconfirm>
                  <el-popconfirm
                    v-if="row.status_ != 1 && allJurisdiction.goodsAClassify143"
                  >
                    
                  </el-popconfirm> -->
                  <div
                    @click="getEdit(row)"
                    v-if="allJurisdiction.goodsAClassify142"
                  >
                    编辑
                  </div>
                  <!-- todeltel -->
                  <!-- <el-popconfirm
                    v-if="allJurisdiction.goodsAClassify144"
                  >
                    
                  </el-popconfirm> -->

                  <div @click="transferGoods(row)" slot="reference">
                    商品转移
                  </div>
                </div>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
    </div>

    <!-- 新建编辑弹框 -->
    <div>
      <el-dialog :title="AddData.id?'编辑分类':'新建分类'" :visible.sync="isAdd" width="600px">
        <div>
          <el-form ref="form" label-width="80px">
            <el-form-item label="上级分类" v-if="!AddData.id">
              <el-cascader
                v-model="AddDataparent_id"
                :options="classList"
                :props="propsData"
                clearable
              ></el-cascader>
            </el-form-item>
            <el-form-item
              label="上级分类"
              v-if="AddData.id && AddData.parent_id != 0"
            >
              <el-select
                :disabled="true"
                v-model="AddData.parent_id"
                placeholder="请选择"
                filterable
              >
                <el-option
                  v-for="item in tegOpent"
                  :key="item.id"
                  :label="item.category_name"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="分类名称" :required="true">
              <el-input v-model="AddData.category_name"></el-input>
            </el-form-item>
            <el-form-item label="排序" :required="true">
              <el-input v-model="AddData.sort" type="number"></el-input>
            </el-form-item>
            <el-form-item label="图标" :required="true">
              <div class="up-box">
                <el-upload
                  :action="Actions"
                  list-type="picture-card"
                  :limit="1"
                  :headers="token"
                  :file-list="imageList"
                  :before-upload="beforeAvatarUpload"
                  :data="{ type: '1' }"
                  :on-success="successUpImg"
                >
                  <i class="el-icon-plus"></i>
                  <div slot="file" slot-scope="{ file }">
                    <img
                      class="el-upload-list__item-thumbnail"
                      :src="file.url"
                      alt=""
                    />
                    <span class="el-upload-list__item-actions">
                      <span
                        class="el-upload-list__item-delete"
                        @click="handleRemove(file)"
                      >
                        <i class="el-icon-delete" title="删除" />
                      </span>
                    </span>
                  </div>
                </el-upload>
              </div>
            </el-form-item>
            <el-form-item
              label="状态"
              v-if="allJurisdiction.goodsAClassify143"
              :required="true"
            >
              <el-radio v-model="AddData.status_" label="1">启用</el-radio>
              <el-radio v-model="AddData.status_" label="0">停用</el-radio>
            </el-form-item>
          </el-form>
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button @click="isAdd = false">取 消</el-button>
          <el-button type="primary" @click="confirmAdd">
            <span v-if="!AddData.id">新建</span>
            <span v-else>保存</span>
            </el-button>
        </span>
      </el-dialog>
    </div>

    <!-- 商品转移弹框 -->
    <div>
      <el-dialog title="商品转移" :visible.sync="isTransfer" width="600px">
        <div>
          <span class="diaSpan"
            >您确定要将当前商品分类已关联的的
            <span >{{ goods_count }}</span>   
            条商品进行转移操作？请选择需要转移目标商品分类</span
          >
          <br />
          <el-cascader
            class="diaInput"
            placeholder="选择商品分类"
            v-model="cascaderValue"
            :options="BasissCopeData.Newgoods_categoryList"
            :props="propsData"
            @change="cascaderhandleChange"
            clearable
          ></el-cascader>
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button @click="isTransfer = false">取 消</el-button>
          <el-button type="primary" @click="confirmTransfer">转 移</el-button>
        </span>
      </el-dialog>
    </div>
    <el-dialog
      :title="Dialog.title"
      :visible.sync="DialogVisible"
      width="422px"
    >
      <span class="Dialog-mess">{{ Dialog.mess }}</span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="DialogVisible = false" v-if="Dialog.type!=3">取 消</el-button>
        <el-button type="primary" @click="affirm()">{{
          Dialog.footerName
        }}</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { getToken } from "@/utils/auth";
import { createNamespacedHelpers } from "vuex";
const commonIndex = createNamespacedHelpers("commonIndex"); //vuex公共库
import { wholeUrl } from "@/api/phpUrl"; //引入接口
// import { categoryList } from "@/utils/index";

import compressImage from "@/utils/compressImage.js";
const { mapState, mapActions } = createNamespacedHelpers("Goods"); //vuex公共库
export default {
  data() {
    return {
      searchData: {
        status: "",
      },
      classList: [], //列表数据
      isAdd: false,
      isTransfer: false,
      AddDataparent_id: [],
      AddDataparent_idText: "",
      AddData: {}, //新建数据
      Actions: wholeUrl + "/basis/upload",
      token: { token: getToken() },
      imageList: [],
      tegOpent: [],
      userType: [
        {
          type: 1,
          name: "启用",
        },
        {
          type: 0,
          name: "停用",
        },
      ],
      Dialog: {
        title: "",
        mess: "",
        type: "",
        footerName: "",
      },
      DialogVisible: false,
      ycApi: {},
      // propsData: {
      //   children: "son",
      //   label: "category_name",
      //   value: "id",
      //   checkStrictly: true,
      // },
      propsData: {
        value: "id",
        label: "category_name",
        children: "son",
        disabled: "forbidden",
        checkStrictly: true,
        // multiple: true
        //级联选择器配置项
      },
      cascaderValue: "",
      cascaderOptions: [],
      category: [],
      new_category: [],
      goods_count: "", //商品数
    };
  },
  computed: {
    ...commonIndex.mapState([
      "AreaList",
      "mainHright",
      "allJurisdiction",
      "FinilsCategoryList",
      "BasissCopeData",
    ]),
  },
  created() {
    this.toSearch();

  },
  methods: {
    ...commonIndex.mapActions(["categorycategory", "getcategorylist"]),
    ...mapActions([
      "postcategoryadd",
      "getcategorycategory",
      "getcategoryedit",
      "postcategoryedit",
      "postcategorydelete",
      "posttransferGoods",
      "getGetgoodsInfodetail",
    ]),
    async affirm() {
      if (this.Dialog.type == 0 || this.Dialog.type == 1) {
        this.checkType();
      }
      if (this.Dialog.type == 2) {
        this.todeltel(this.Dialog);
      }
      if(this.Dialog.type == 3){
        this.DialogVisible=false
      }
    },
    async openDilog(val, type) {
      if (type == 0) {
        this.Dialog = {
          title: "禁用商品分类提醒",
          mess: "确实要禁用当前商品分类吗？禁用后创建/编辑商品时无法选择分类。",
          type,
          footerName: "禁用",
        };
      }
      if (type == 1) {
        this.Dialog = {
          title: "启用商品分类提醒",
          mess: "确实要启用当前商品分类？启用后创建/编辑商品时用户将可以选择该分类。",
          type,
          footerName: "启用",
        };
      }
      if (type == 2) {
        this.Dialog = {
          title: "删除商品分类提醒",
          mess: "确定要删除当前商品分类？删除后当前商品分类无法恢复，请谨慎操作",
          type,
          footerName: "确定",
        };
      }
      this.Dialog.id = val.id;
      this.DialogVisible = true;
    },
    async beforeAvatarUpload(file) {
      if (file.size / 1024 / 1024 > 50)
        return this.$message.error("上传头像图片大小不能超过 50MB!");
      // return await compressImage([file]);
    },
    handleCurrentChange() {},
    // 调整次级联动结构格式
    // cgOptions() {
    //   setTimeout((v) => {
    //     this.cascaderOptions = categoryList(this.FinilsCategoryList);
    //   }, 1000);
    // },
    cascaderhandleChange(value) {
      this.new_category = value;
      // this.ycApi.category_id = value[value.length - 1];
    },
    // 新增
    async confirmAdd() {
      this.AddData.parent_id =
        this.AddDataparent_id[this.AddDataparent_id.length - 1] || "";
      if (
        !this.AddData.category_name ||
        !this.AddData.sort ||
        !this.AddData.icon ||
        !this.AddData.status_
      ) {
        this.$message({
          message: "请将信息填写完整",
          type: "warning",
        });
        return;
      }
      this.AddData.parent_id
        ? this.AddData.parent_id
        : (this.AddData.parent_id = 0);
      let data = {};
      if (this.AddData.id) {
        data = await this.postcategoryedit(this.AddData);
      } else {
        data = await this.postcategoryadd(this.AddData);
      }
      if (data.code == 200) {
        this.toSearch();
        this.isAdd = false;
        this.$message({
          message: data.message,
          type: "success",
        });
        this.categorycategory();
      } else {
        this.$message({
          message: data.message,
          type: "warning",
        });
      }
    },
    // 前往新增
    toAdd() {
      this.AddData = {};
      this.imageList = [];
      this.GETsel();
      this.isAdd = true;
    },
    // 删除
    async todeltel(row) {
      //console.log(row);
      let data = await this.postcategorydelete({ id: row.id });
      if (data.code == 200) {
        if (data.data.errCode == 0) {
          this.DialogVisible = false;
          this.toSearch();
          this.$message({
            message: data.message,
            type: "success",
          });
          this.categorycategory();
        } else if (data.data.errCode == 1) {
          this.Dialog = {
          mess: "你无法删除当前商品分类，因为当前商品分类下存在子级分类。",
          type:3,
          footerName: "知道了",
        };
        } else if (data.data.errCode == 2) {
          this.Dialog = {
          mess: "你无法删除当前商品分类，因为当前商品分类下存在商品。",
          type:3,
          footerName: "知道了",
        };
        }
      } else {
        this.$message({
          message: data.message,
          type: "warning",
        });
      }
    },
    // 获取菜单详情
    async getEdit(row) {
      this.imageList = [];
      let data = await this.getcategoryedit({ id: row.id });
      if (data.code == 200) {
        //console.log(data);
        this.AddDataparent_idText = data.data.category_name;
        this.GETsel();
        this.AddData = data.data;
        this.imageList = [{ url: data.data.icon }];
        this.isAdd = true;
      } else {
        this.$message({
          message: data.message,
          type: "warning",
        });
      }
    },
    // 获取列表
    async toSearch() {
      let data = await this.getcategorylist({ status: this.searchData.status });
      this.classList = data.data;
    },
    //商品转移
    async transferGoods(row) {
      // let res=await this.posttransferGoods()
      // if (row.level == 1) {
      //   this.category = [row.all_parent,row.id];
      // } else if (row.level == 2) {
      //   this.category = [row.parent_id - 0, row.id];
      // } else {
      //   if (row.all_parent) {
      //     let arr = row.all_parent.split(",");
      //     arr[0] - 0;
      //     this.category = [arr[0] - 0, arr[1] - 0, row.id];
      //   }
      // }
      row.id=row.id+''
      if(!row.all_parent){
        
        this.category=[row.id]
      }else{
        let arr = row.all_parent.split(",");
        this.category=[...arr,row.id]
      }
     
      let res = await this.getGetgoodsInfodetail({ category: this.category });
      this.goods_count = res.data.goods_count;
      this.isTransfer = true;
    },
    async confirmTransfer() {
      let res = await this.posttransferGoods({
        category: this.category,
        new_category: this.new_category,
      });
      if (res.code == 200) {
        this.$message({
          message: res.message,
          type: "success",
        });
        this.isTransfer = false;
      } else {
        this.$message({
          message: res.message,
          type: "warning",
        });
      }
    },
    //筛选项
    async GETsel() {
      let data = await this.getcategorycategory();
      //console.log(data, "123");
      this.tegOpent = data.data;
      //console.log(this.tegOpent,'12312');
    },
    // 图片上传成功
    successUpImg(data) {
      //console.log(data.data.full_url, "132");
      if (data.code == 200) {
        this.AddData.icon = data.data.full_url;
        this.$message({
          message: data.message,
          type: "success",
        });
      } else {
        this.$message({
          message: data.message,
          type: "warning",
        });
      }
      //
    },
    //状态切换
    async checkType() {
      let data = await this.postcategoryedit({
        id: this.Dialog.id,
        status_: this.Dialog.type == 0 ? "0" : "1",
      });
      if (data.code == 200) {
        this.DialogVisible = false;
        this.toSearch();
        this.$message({
          message: data.message,
          type: "success",
        });
      } else {
        this.$message({
          message: data.message,
          type: "warning",
        });
      }
    },
    // 删除图片
    handleRemove(data) {
      this.imageList = [];
    },
    // tag标签切换
    handleClick(tab, event) {
      //console.log(tab, event);
    },
    toMessage() {},
  },
};
</script>
<style lang='scss' scoped>
.el-table__indent {
  padding-left: 0px !important;
}
</style>
<style lang="scss" scoped>
.dio-input {
  .el-input {
    width: 300px !important;
  }
  .el-upload--picture-card {
    width: 100px;
    height: 100px;
    line-height: 100px;
  }
}
.el-tabs__item.is-active {
  color: #06b7ae;
}
.el-tabs__item:hover {
  color: #06b7ae;
}
.el-tabs__active-bar {
  background-color: #06b7ae;
}
.el-input-group__prepend {
  padding: 0px 0px 0px 5px !important;
}
.el-input__inner {
  border-radius: 4px !important;
}
.el-range-separator {
  width: 20% !important;
}

.el-input__inner{
  line-height: 1px !important;
}

input[type="number"]::-webkit-inner-spin-button, input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
</style>
<style lang="scss" scoped>
.userBtn {
  display: flex;
  justify-content: center;
  div {
    margin-left: 5px;
    color: #1abca9;
    cursor: pointer;
  }
}
.MERCH {
  width: 100%;
  height: 100%;
  .merch-card {
    width: 100%;
    max-height: 100%;
    padding: 20px;
    padding-top: 10px;
    border-radius: 4px;
    box-shadow: 0 2px 12px 0 rgb(0 0 0 / 10%);
    background: #ffffff;
    .merch-tag {
      width: 100%;
      height: 40px;
    }
    // 搜索行样式
    .merch-search {
      margin-top: 1%;
      width: 100%;
      .search-box {
        display: flex;
        flex-wrap: wrap;
      }
      .sch-1 {
        margin-top: 10px;
        width: 18%;
        min-width: 200px;
        display: flex;

        .sch-title {
          width: 40%;
          min-width: 80px;
          height: 100%;
          font-size: 13px;
          background-color: #f5f7fa;
          color: #909399;
          border: 1px solid #dcdfe6;
          border-right: none;
          border-radius: 4px 0px 0px 4px;
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 0px 0px 0px 5px !important;
        }
      }
      .sch-2 {
        margin-right: 2%;
      }
    }
    .merch-table {
      margin-top: 1%;
      width: 100%;
      max-height: 75vh;
    }
    .merch-page {
      margin-top: 10px;
      width: 100%;
      display: flex;
      align-content: center;
      justify-content: right;
    }
  }
}
.diaSpan {
  font-size: 14px;
  font-family: PingFang SC-Medium, PingFang SC;
  font-weight: 500;
  color: #1d2129;
  line-height: 22px;
  span{
    color: #06B7AE;
    font-size: 20px;
  }
}
.diaInput {
  width: 100%;
  margin-top: 25px;
}
.Dialog-mess {
  color: #1d2129;
  line-height: 22px;
}
::v-deep .el-dialog__body {
  padding-top: 10px;
}

</style>
